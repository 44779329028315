<template>
  <div class="main">
    <van-nav-bar
      title="兑换详情"
      left-arrow
      @click-left="handleBack"
      :border="false"
      fixed
    />
    <div class="detail-header"></div>
    <div class="detail-qrcode">
      <div class="qrcode">
        <div class="qrcode-content">
          <div class="qrcode-img" id="qrcodeEl" ref="qrcodeEl"></div>

          <div v-if="detailData.sendStatus == '04'" class="qrcode-content-status">
            <img
              class="qrcode-content-status-img"
              :src="require('../../static/images/v3/complate.png')"
              alt=""
            />
          </div>
        </div>
      </div>

      <div class="detail-exchange">
        <div class="detail-exchange-no">核销凭证：{{ detailData.cardNo || "-" }}</div>
        <div class="detail-exchange-date">兑换日期：{{ detailData.reqDateTime || '-'}}</div>
      </div>
    </div>

    <div class="detail-product">
      <!-- header -->
      <div style="display: flex">
        <div>
          <van-image
            fit="cover"
            round
            class="product-item-img"
            :src="detailData.goodsImg"
          />
        </div>
        <div style="padding-left: 10px">
          <div class="product-item-title">{{ detailData.goodsName || '-'}}</div>
          <div class="product-item-point">{{ parseInt(detailData.goodsPoints / 120) }}磁石</div>
          <div class="product-item-point">x {{ detailData.goodsNum }}</div>
        </div>
      </div>
      <van-divider style="padding: 0; margin: 10px 0" />
      <div class="detail-product-exinfo">
        <div>兑换磁石</div>
        <div class="detail-product-exinfo-val">{{ detailData.exchangeValue }}磁石</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Row,
  Col,
  NavBar,
  Image,
  Field,
  Popup,
  Step,
  Steps,
  PullRefresh,
  List,
  Cell,
  Divider,
} from "vant";
import {
  getSendDeliverInfo
} from "@/api/global";
import QRCode from "qrcodejs2";

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [NavBar.name]: NavBar,
    [Image.name]: Image,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [Divider.name]: Divider,
  },

  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,

      detailData: {}
    };
  },
  created() {
    if (/micromessenger/.test(window.navigator.userAgent.toLowerCase())) {
      this.channel = "使用微信";
    } else if (/AlipayClient/.test(window.navigator.userAgent)) {
      this.channel = "使用支付宝";
    } else {
      this.channel = "";
    }

    this.loadData()

    this.$nextTick(() => {
      this.createQrcode("qrcodeEl", "item.reqOrderId");
    });
  },
  mounted() {},
  methods: {
    loadData() {
      let data = {}
      if (this.$route.query.channelCode) {
        data.channelCode = this.$route.query.channelCode
      }

      if (this.$route.query.id) {
        data.transCode = this.$route.query.id
      }

      if (this.$route.query.merId) {
        data.merId = this.$route.query.merId;
      }

      if (this.$route.query.storeId) {
        data.storeId = this.$route.query.storeId;
      }

      if (this.$route.query.ddback) {
        data.ddback = this.$route.query.ddback
      }

      if (this.$route.query.transCode) {
        data.transCode = this.$route.query.transCode
      }

      if (this.$route.query.reqOrderId) {
        data.reqOrderId = this.$route.query.reqOrderId
      }

      getSendDeliverInfo(data)
        .then((res) => {
          console.log('res >>> ', res)
          
          if (res && res.length > 0) {
            this.detailData = res[0]
          }
        })
        .catch((err) => {});
    },
    createQrcode(code) {
      this.qr = new QRCode("qrcodeEl", {
        width: 180, // 设置宽度，单位像素
        height: 180, // 设置高度，单位像素
        text: code, // 设置二维码内容或跳转地址
      });
    },
    handleBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  min-height: 100vh;
  background-color: #f8f8f8;
}

:deep(.van-nav-bar__content) {
  background-color: #006bd9;
}

:deep(.van-nav-bar) {
  background-color: #006bd9;
}

:deep(.van-nav-bar__title) {
  color: #fff !important;
}

:deep(.van-icon) {
  color: #fff !important;
}

.detail-header {
  width: 100%;
  height: 150px;
  background-color: #ff533c;
  margin-top: -2px;
}

.detail-qrcode {
  margin: -80px 10px 15px;
  background-color: #fff;
  border-radius: 10px;
  // height: 300px;
}

.qrcode {
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 15px 0;

  &-content {
    padding: 15px;
    position: relative;
    width: 180px;
    height: 180px;
    background-color: #fff;
    border-radius: 10px;

    &-status {
      position: absolute;
      top: 0;
      left: 0;
      width: 210px;
      height: 210px;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 10px;

      &-img {
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 60px;
        height: 60px;
      }
    }
  }
}

.detail-exchange {
  padding: 0 15px 15px;

  &-no {
    font-size: 14px;
    font-weight: 600;
  }

  &-date {
    padding-top: 3px;
    font-size: 10px;
    color: #666;
  }
}

.detail-product {
  background-color: #fff;
  padding: 10px;

  &-exinfo {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666;
    justify-content: space-between;

    &-val {
      color: #fbae54;
    }
  }
}

.product-item {
  padding: 20px;

  &-img {
    width: 80px;
    height: 80px;
    border-radius: 8px;
  }

  &-title {
    font-size: 16px;
    padding-top: 0px;
    color: #333;
  }

  &-point {
    padding-top: 5px;
    font-size: 13px;
    color: #fbae54;
  }

  &-count {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-tiplab {
    color: #333;
    font-size: 15px;
  }
}
</style>
